<template>
	<Toast />
	<iframe v-show='false' ref='save_file' src="saveFile/saveFile.html" frameborder="0"
		style="width: 100%;height:100px;"></iframe>
	<div class="layout-topbar" style="justify-content: space-between;">
		<button class="p-link layout-menu-button layout-topbar-button" style="margin-left: 10px;" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
		<!-- 		<router-link style="float: right;" to="/xmlvisualization" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>空间数字图谱构建系统</span>
		</router-link> -->
		<!-- 				<div style="display: flex;flex-direction: row;margin-top: auto;">
			<h5 title='在萤火虫中查询过的单词会自动记录,用户可编辑并导出词汇,详情请看个人词库构建教程' style="margin-top: auto;">去萤火虫构建个人词库<i style="margin-left: 5px;" class='pi pi-question-circle'></i></h5>
			<a href="https://firefly.libertynlp.com/#/" target="_blank">
				<Tag class="mr-2" severity="info" style="margin-left: 20px;" value="前往萤火虫"></Tag>
			</a>
			<a href="https://www.bilibili.com/video/BV11b4y1q7G1/" target="_blank">
				<Tag class="mr-2" style="margin-left: 20px;" value="个人词库构建教程"></Tag>
			</a>
		</div> -->
		<!-- <button class="p-link  layout-topbar-button" style="margin-left: 10px;display: flex;flex-direction: row;" @click="toggle">
			<i class="pi pi-wallet"></i>赞赏
		</button> -->
		<div class="grid formgrid" style="margin-top: 7px;">
			<!-- <h5 title='any question, contact me' style="margin: auto;">水印照片<i
					style="margin-left: 5px;" class='pi pi-question-circle'></i></h5> -->
			<!-- <Button class="p-button-success" @click='changeLanguage("en")' severity="help" style="margin-left: 30px;" label="English">
			</Button> -->
			<Button title='导出配置文件' label="导出" icon="pi pi-download" @click="outputPrintConfig"  iconPos="left" class="p-button-info mr-2 mb-2"></Button>
			<Button title='导入配置文件' label="导入" icon="pi pi-upload" @click="openFile"  iconPos="left" class="p-button-warning mr-2 mb-2"></Button>
			<Button title='重置配置文件为系统默认值并刷新页面' label="重置" icon="pi pi-refresh" @click="refreshConfig"  iconPos="left" class="p-button-success mr-2 mb-2"></Button>
			<Button v-if='false' label="赞赏支持" icon="pi pi-wallet" @click="toggle"  iconPos="left" class="p-button-success mr-2 mb-2"></Button>
			<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
				<img src="images/my_wechat_2.jpg" style="width: 300px;" />
			</OverlayPanel>
			<!-- <Button label="赞赏支持" icon="pi pi-bookmark" @click='changeLanguage("zh-cn")'  iconPos="left" class="p-button-info mr-2 mb-2"></Button> -->
		</div>
		<!-- <button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="toggleSetting($event)">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
		</ul> -->
	</div>
</template>

<script>
	export default {
		methods: {
			toggle(event) {
				this.$refs.op.toggle(event);
			},
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			onTopbarMenuToggle(event) {
				this.$emit('topbar-menu-toggle', event);
			},
			toggleSetting() {
				// console.log(event);
				this.$emit('toggleSetting');
			},
			topbarImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			},
			changeLanguage(new_language) {
				this.$appState.language = new_language;
			},
			outputPrintConfig(){
				var config_his = localStorage.getItem('exif_print_config');
				// var json_his = JSON.parse(config_his);
				this.$refs.save_file.contentWindow.saveJsonFile('水印配置文件.json', config_his);
			},
			openFile(file_type) {
				var this_e = document.getElementById('choose_file');
				this_e.click();
				this.file_type = file_type;
			},
			refreshConfig(){
				localStorage.setItem('exif_print_config', null);
				window.location.reload();
			}
		},
		mounted() {
			
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>
