<template>
	<Toast />
	<div class="card p-grid p-fluid" v-if='false' style="margin-bottom: 0px;">
		<!-- 各种方法的集合库 -->
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		name: 'Utils',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {

		},
		data() {
			return{
				
			}
		},
		networkService:null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// console.log('组件加载成功');
		},
		methods: {
			//邮箱错误提示
			showError(msg) {
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 5000
				});
			},
			//展示成功信息
			showSusscessInfo(msg){
				this.$toast.add({
					severity: 'success',
					summary: '成功',
					detail: msg,
					life: 3000
				});
			},
			//开始处理
			startProcessing(){
				this.$appState.processing=true;
			},
			//停止处理
			stopProcessing(){
				this.$appState.processing=false;
			},
			//查询单词
			async autoTaged(text_list) {
				// console.log('自动标注处理数据',text_list);
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("original_text_list", JSON.stringify(text_list));
				var url = '/taged/auto-taged';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async saveTaged(file_taged,file_name) {
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("file_taged", file_taged);
				data.append("file_name", file_name);
				var url = '/taged/save-taged';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async getStatisdics(file_taged) {
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("file_taged", file_taged);
				var url = '/taged/taged-statisdics';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async getCorpusList() {
				this.startProcessing();
				var url = '/taged/corpus-list';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url,{});
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async getCorpusInfo(corpus_meta) {
				this.startProcessing();
				var url = '/taged/corpus-info';
				var data = new URLSearchParams();
				data.append("corpus_meta", JSON.stringify(corpus_meta));
				var resp = await this.networkService.postService(url,data);
				this.stopProcessing();
				return resp;
			},
			async deleteCorpus(corpus_meta) {
				this.startProcessing();
				var url = '/taged/delete-corpus';
				var data = new URLSearchParams();
				data.append("corpus_meta", JSON.stringify(corpus_meta));
				var resp = await this.networkService.postService(url,data);
				this.stopProcessing();
				return resp;
			},
			//查询单词
			async searchDictWord(search_words) {
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("search_words", search_words);
				var url = '/paper/search-dict-words';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				return resp;
			},
			copyText(text) {
				var tips_language = 'en';
				var tips = {
					'zh-cn': [
						'复制成功！',
					],
					'en': [
						'Copied!',
					]
				} [tips_language];
			
				// 数字没有 .length 不能执行selectText 需要转化成字符串
				const textString = text.toString();
				let input = document.querySelector('#copy-input');
				if (!input) {
					input = document.createElement('input');
					input.id = "copy-input";
					input.readOnly = "readOnly"; // 防止ios聚焦触发键盘事件
					input.style.position = "absolute";
					input.style.left = "-2000px";
					input.style.zIndex = "-2000";
					document.body.appendChild(input)
				}
			
				input.value = textString;
				this.selectText(input, 0, textString.length); // ios必须先选中文字且不支持 input.select();
				if (document.execCommand('copy')) {
					document.execCommand('copy');
					alert(text+ '    ' + tips[0]);
				}
				input.blur();
			},
			// input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
			// 选择文本。createTextRange(setSelectionRange)是input方法
			selectText(textbox, startIndex, stopIndex) {
				if (textbox.createTextRange) { //ie
					const range = textbox.createTextRange();
					range.collapse(true);
					range.moveStart('character', startIndex); //起始光标
					range.moveEnd('character', stopIndex - startIndex); //结束光标
					range.select(); //不兼容苹果
				} else { //firefox/chrome
					textbox.setSelectionRange(startIndex, stopIndex);
					textbox.focus();
				}
			},
		},
	}
</script>

<style scoped lang="scss">
</style>
