import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue')
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue')
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue')
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue')
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue')
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue')
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue')
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue')
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlayDemo.vue')
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue')
    },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue')
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue')
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue')
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue')
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue')
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue')
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartDemo.vue')
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue')
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue')
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue')
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue')
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue')
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./components/IconsDemo.vue')
    },
	{
		path: '/xmlvisualization',
		name: 'xmlvisualization',
		component: () => import('./pages/XmlVisualization.vue')
	},
	{
		path: '/relationtags',
		name: 'RelationTags',
		component: () => import('./pages/RelationTags.vue')
	},
	{
		path: '/',
		name: 'Login',
		component: () => import('./pages/Login.vue')
	},
	{
		path: '/pdfjs-annotation',
		name: 'pdfjs',
		component: () => import('./pages/pdfjs.vue')
	},
	{
		path: '/googleads',
		name: 'GoogleAds',
		component: () => import('./pages/GoogleAds.vue')
	},
	{
		path: '/payme',
		name: 'PayMe',
		component: () => import('./pages/Payme.vue')
	},
	{
		path: '/pdfjs-cors',
		name: 'PdfCors',
		component: () => import('./pages/pdfjs-cors.vue')
	},
	{
		path: '/admininfo',
		name: 'AdminInfo',
		component: () => import('./pages/AdminInfo.vue')
	},
	{
		path: '/dealrecords',
		name: 'AdminInfo',
		component: () => import('./pages/DealRecords.vue')
	},
	{
		path: '/search',
		name: 'Search',
		component: () => import('./pages/dict/Search.vue')
	},
	{
		path: '/authkey',
		name: 'AuthKey',
		component: () => import('./pages/AuthKey.vue')
	},
	{
		path: '/pricing',
		name: 'Pricing',
		component: () => import('./pages/Pricing.vue')
	},{
		path: '/pageOperator',
		name: 'pageoperator',
		component: () => import('./pages/pageOperator.vue')
	},
	{
		path: '/exifprinter-splitter',
		name: 'exifprinter-splitter',
		component: () => import('./pages/exifprinter-splitter.vue')
	},
	{
		path: '/exifprinter',
		name: 'exifprinter',
		component: () => import('./pages/exifprinter.vue')
	},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
